export type NumberOptions = {
  [key: number]: string;
};
export const BOOL_OPTIONS: NumberOptions = {
  0: "指定なし",
  1: "あり",
};

//医療保険
export const CANCER_SPECIAL_OPTIONS: NumberOptions = {
  0: "指定なし",
  500000: "50万円",
  1000000: "100万円",
};
export const HOSPITALIZATION_GUARANTEE_OPTIONS: NumberOptions = {
  0: "指定なし",
  3000: "3,000円",
  5000: "5,000円",
  8000: "8,000円",
  10000: "10,000円",
  20000: "20,000円",
};
export const HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS: NumberOptions = {
  30: "30日",
  60: "60日",
  120: "120日",
  180: "180日",
  1: "無制限",
};
export const HOSPITALIZATION_ONCE_OPTIONS: NumberOptions = {
  0: "指定なし",
  30000: "3万円",
  50000: "5万円",
  100000: "10万円",
  200000: "20万円",
};
export const COMMUTING_DAILY_OPTIONS: NumberOptions = {
  0: "指定なし",
  3000: "3,000円",
  5000: "5,000円",
  10000: "10,000円",
  30000: "30,000円",
  50000: "50,000円",
  100000: "100,000円",//2022-10 code fix by egoba
  0.5: "入院日額×0.5倍",
  0.1: "入院日額×1倍",//2022-10 code fix by egoba 多分エラーだわ
  // 1: "入院日額×1倍",//2022-10 subtract by egoba
};
export const COMMUTING_DAYS_OPTIONS: NumberOptions = {
  30: "30日",
  60: "60日",
  1: "無制限",
};
export const COMMUTING_ONCE_OPTIONS: NumberOptions = {
  0: "指定なし",
  30000: "3万円",
  50000: "5万円",
  100000: "10万円",
};
export const SPECIAL_CONTRACT_OPTIONS: NumberOptions = {
  0: "指定なし",
  500000: "50万円",
  1000000: "100万円",
};

//がん保険
export const DIAGNOSTIC_ONCE_OPTIONS: NumberOptions = {
  0: "指定なし",
  500000: "50万円",
  1000000: "100万円",
  2000000: "200万円",
  3000000: "300万円",
};
export const DIAGNOSTIC_TREATMENT_OPTIONS: NumberOptions = {
  0: "指定なし",
  50000: "5万円",
  100000: "10万円",
  200000: "20万円",
  300000: "30万円",
  400000: "40万円",
  500000: "50万円",
};
export const CANCER_HOSPITALIZATION_GUARANTEE_OPTIONS: NumberOptions = {
  0: "指定なし",
  5000: "日額5,000円",
  10000: "日額10,000円",
  15000: "日額15,000円",
  20000: "日額20,000円",
};
export const CANCER_HOSPITALIZATION_GUARANTEE_DAYS_OPTIONS: NumberOptions = {
  0: "指定なし",
  60: "60日",
  1: "無制限",
};
export const SURGERY_OPTIONS: NumberOptions = {
  0: "指定なし",
  100000: "10万円",
  200000: "20万円",
  300000: "30万円",
};
export const LEAVING_HOSPITAL_ONCE_OPTIONS: NumberOptions = {
  0: "指定なし",
  50000: "5万円",
  100000: "10万円",
  200000: "20万円",
};
export const CANCER_COMMUTING_DAILY_AMOUNT_OPTIONS: NumberOptions = {
  0: "指定なし",
  3000: "3,000円",
  5000: "5,000円",
  10000: "10,000円",
};
export const RADIOTHERAPY_OPTIONS: NumberOptions = {
  0: "指定なし",
  50000: "5万円",
  100000: "10万円",
  200000: "20万円",
  300000: "30万円",
};

//終身死亡保険
export const WHOLE_DEATH_BENEFIT_OPTIONS: NumberOptions = {
  0: "指定なし",
  2000000: "200万円",
  5000000: "500万円",
  10000000: "1,000万円",
};

//定期死亡保険
export const TERM_DEATH_BENEFIT_OPTIONS: NumberOptions = {
  0: "指定なし",
  1000000: "100万円",
  2000000: "200万円",
  3000000: "300万円",
  4000000: "400万円",
  5000000: "500万円",
  10000000: "1,000万円",
  15000000: "1,500万円",
  20000000: "2,000万円",
  25000000: "2,500万円",
  30000000: "3,000万円",
  35000000: "3,500万円",
  40000000: "4,000万円",
  45000000: "4,500万円",
  50000000: "5,000万円",
  100000000: "1億円",
};

//収入保障保険
export const MONTHLY_BENEFIT_OPTIONS: NumberOptions = {
  0: "指定なし",
  50000: "5万円",
  100000: "10万円",
  150000: "15万円",
  200000: "20万円",
  250000: "25万円",
  300000: "30万円",
  400000: "40万円",
  500000: "50万円",
};
