/** @jsxImportSource @emotion/react */
import "../../styles/pages/intro.scss";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  detailDiagnoseStorage,
  simpleDiagnoseStorage,
  partnerDataStorage,
  PartnerData,
  cartStorage,

  medicalPDStorage,
  cancerPDStorage,
  deathPDStorage,
  wholeDeathPDStorage,
  incomeProtectionPDStorage,

  focusedInsuranceStorage,
  searchConditionStorage,
  commonPDStorage,
} from "../../utils/storage";
import { GetApiPartnersPartnerCode200Response } from "../../openApi/api";
import { useGetPartnerInfo } from "../../query/useGetPartnerInfo";

const Top = () => {
  useDocumentTitle("保デジWEB");
  const navigate = useNavigate();
  
  const urlParams = new URLSearchParams(window.location.search);
  const partnerCode = urlParams.get("partner") ?? undefined;
  const inflowCode = urlParams.get("inflow") ?? undefined;
  const callback = (data: GetApiPartnersPartnerCode200Response) => {
    const partnerData: PartnerData = {
      partnerCode: partnerCode,
      inflowCode: inflowCode,
      mainColor: data.main_color,
      subColor1: data.sub_color_1,
      subColor2: data.sub_color_2,
      subColor3: data.sub_color_3,
      fontColor: data.font_color,
      accentColor: data.accent_color,
      backgroundColor: data.background_color,
      mainButtonColor: data.main_button_color,
      subButtonColor1: data.sub_button_color_1,
      subButtonColor2: data.sub_button_color_2,
      subButtonColor3: data.sub_button_color_3,
      saveButtonDisp: data.save_button_disp,
      fpButtonDisp: data.fp_button_disp,
      comparisonButtonDisp: data.comparison_button_disp
    };
    partnerDataStorage.set(partnerData);
  };

  useGetPartnerInfo({
    partnerCode,
    callback,
  });
  
  if(!partnerCode){
    partnerDataStorage.remove();
  }
  
  const onStartSimpleDiagnose = () => {
    navigate("/simple");
  };
  
  simpleDiagnoseStorage.remove();
  detailDiagnoseStorage.remove();
  cartStorage.removeAll();
  
  medicalPDStorage.remove();
  cancerPDStorage.remove();
  deathPDStorage.remove();
  wholeDeathPDStorage.remove();
  incomeProtectionPDStorage.remove();
  
  focusedInsuranceStorage.remove();
  searchConditionStorage.remove();
  commonPDStorage.remove();
  
  return (
    <div className="topStartView">
      <section className="topBox">
        <div className="wrap">
          <span className="subTitle">かんたん</span>
          <span className="topTitle">必要保障額</span>
          <span className="topTitle">シミュレーション</span>
          <img src="/img/logo.svg" alt="保デジ" />
        </div>
        <div className="baloon">
          <img src="/img/top_ttl.svg" alt="40秒でチェック!保険料はもっと節約できる!?" />
        </div>
      </section>
      <div className="personBox">
        <img src="/img/top_background.svg" alt="bg" />
      </div>
      <section className="introBox">
        <img src="/img/calcManBlue.svg" alt="person" />
        <div className="message">
          <p>
            まずは10個ほどのかんたんな質問にお答えください。
            <br />
            必要保障額と最適な月々の保険料を算出し、おすすめの医療保険・死亡保険をご提案します。
          </p>
        </div>
      </section>
      <div className="startBox">
        <button onClick={onStartSimpleDiagnose}>
          <span>
            診断スタート
          </span>
        </button>
      </div>
    </div>
  );
};

export default Top;
